import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import { forceCheck } from 'react-lazyload'

import find from 'lodash/find'
import get from 'lodash/get'
import has from 'lodash/has'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import keys from 'lodash/keys'
import flatMapDeep from 'lodash/flatMapDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import transform from 'lodash/transform'
import orderBy from 'lodash/orderBy'
import * as _sortBy from 'lodash/sortBy'
import memoize from 'lodash/memoize'
import flattenDeep from 'lodash/flattenDeep'

import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'

import { _ } from 'Services/I18n'

import { withAppContext } from 'Services/Context'
import { geoOptions, handleGeoError } from 'Services/Utils/geo'
import * as Utils from 'Services/Utils'

import {
  processLocations,
  processMerchants,
  processBucketForSearchPathParams,
  processFeaturedCategories,
  processFeaturedLocations,
  processSupplementaryCategories,
  processCategories,
  processBuildCategoryValue,
  processFindCategoryValueFromUrl,
  processBucketForAllResultsSearchPathParams,
  processBucketForOnlineSearchPathParams,
} from 'Services/Utils/merchants'

import { PUBLIC_PATHS } from 'Constants/paths'
import Labels from 'Constants/labels'
import {
  MARKETPLACE_SERVICE_TYPE,
  MARKETPLACE_DROPDOWN_TEXT,
  SEARCH_TYPE,
  SEARCH_VIEW,
  CONTENT_PADDING_X,
} from 'Constants/ids'

import {
  ErrorContent,
  SearchDatePicker,
  SearchResult,
  SearchSelect,
  SearchTypeSwitch,
  NotFound,
} from 'Components/Blocks'

import { Dash, Pagination } from 'Components/UI'

import { compassGlyph, globeGlyph } from 'Assets/Svg'
import { MapPinIcon } from 'Assets/Svg/General'

import {
  Container,
  Responsive,
  Content,
  MainContent,
  LoaderOverlay,
  Loader,
  Row,
  AdjustIcon,
  SearchLabel,
  MobileSearchFieldsContainer,
  MobileFiltersToggleContainer,
  MobileFiltersContainer,
  MobileFiltersContainerTitle,
  MobileFiltersCountBadge,
  CloseMobileFiltersIcon,
  MobileFiltersShowResultsButton,
  MobileFiltersResultsButtonContainer,
  StyledBreadcrumbs,
  SearchDatePickerHolder,
  FilterContainer,
  ScrollContainer,
  MobileFiltersOverlay,
} from './styles'

import Filters from './Filters'
import SearchFields from './SearchFields'
import MobileSearchFields from './MobileSearchFields'
import DesktopLeftSide from './DesktopLeftSide'
import SearchResultsPanel from './SearchResultsPanel'

const initOptions = ({
  baseTheme,
  marketplaceType,
  marketplaceServiceType,
}) => {
  const options = []

  // Hide 'search near me' if service type is online
  if (marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online) {
    options.push({
      label: _(MARKETPLACE_DROPDOWN_TEXT[marketplaceType]),
      labelIcon: compassGlyph,
      value: 'near',
      type: 'near',
      metadata: {
        type: 'h1',
      },
    })
  }

  // Show online option if mixed marketplace
  if (marketplaceServiceType === MARKETPLACE_SERVICE_TYPE.onlineAndPhysical) {
    options.push({
      label: get(baseTheme, `labels.${Labels.PLACEHOLDER_ONLINE_SERVICES}`),
      labelIcon: globeGlyph,
      value: 'online',
      type: 'online',
      metadata: {
        type: 'h1',
      },
    })
  }

  return options
}

class Search extends PureComponent {
  getCategoryFilterOptions = memoize(categories => {
    const { options } = processFeaturedCategories({
      categories,
      type: 'non_supplementary',
    })

    return _sortBy(options, 'label')
  })

  getTypeFilterOptions = memoize(categories => {
    const { options } = processSupplementaryCategories({
      categories,
    })

    return _sortBy(options, 'label')
  })

  getLocationFilterOptions = memoize(featuredLocations => {
    return processFeaturedLocations({
      locations: featuredLocations,
    }).options
  })

  getFeaturedLocationValue = memoize(
    ({ searchValue, featuredLocationOptions, search }) => {
      return (
        find(featuredLocationOptions, [
          ['value', 'bucket_id'],
          Number(search?.value),
        ]) ?? searchValue
      )
    },
  )

  getActiveFilters = memoize(customFilter => {
    if (customFilter.name === 'customFilterOptions') {
      return get(this.state, `customFilterOptions.${customFilter.id}`, [])
    }

    return get(this.state, `${customFilter.name}FilterIds`, [])
  })

  inputRef = React.createRef()

  debounceSearch = debounce(search => this.handleSearch(search), 400)

  debounceCategorySearch = debounce(
    categorySearch => this.handleCategorySearch(categorySearch),
    400,
  )

  constructor(props) {
    super(props)

    const { baseTheme, match } = this.props

    const urlQueryParams = get(match, 'params', {})
    const {
      bucketType,
      bucketId,
      bucketFullName,
      categoryId,
      categoryFullName,
      currentPage,
    } = urlQueryParams

    const marketplaceType = get(baseTheme, 'marketplace_type')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    this.state = {
      currentPage: Number(currentPage || 1),
      sortBy: '',
      descOrAsc: 'asc',
      options: initOptions({
        baseTheme,
        marketplaceType,
        marketplaceServiceType,
      }),
      bucketName: '',
      bucketId,
      bucketType,
      bucketFullName,
      categoryId,
      categoryFullName,
      customFilter1FilterIds: [],
      customFilter2FilterIds: [],
      customFilter3FilterIds: [],
      customFilterOptions: {},
      distanceFilter: 100,
      search: '',
      isLoadingGeo: false,
      geoError: null,
      mobileFilter: false,
      cacheFilters: {},
      disabledSearch: false,
      searchResults: null,
      disabledCategorySearch: false,
      categorySearch: '',
      categoryOptions: [],
      marketplaceType,
      searchView: '',
    }
  }

  async componentDidMount() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    const { bucketType, bucketId } = this.state

    const { baseTheme, history, onSetCategoryValue } = this.props
    const serviceCategory = get(baseTheme, 'service_category') ? 1 : 0

    let categoryValue = get(this.props, 'categoryValue')
    if (serviceCategory) {
      categoryValue = await this.handleLoadCategories(categoryValue)

      if (isEmpty(categoryValue)) {
        const params = get(this.props, 'match.params')
        const categoryId = get(params, 'categoryId')
        this.handleSetNextCategoryValue({ categoryId })
      } else {
        await onSetCategoryValue(categoryValue)
      }
    }

    const state = get(history, 'location.state')

    const cacheFilters =
      get(state, 'cacheFilters') ||
      (bucketType !== 'near' ? { [bucketId]: null } : {})

    const customFilter1FilterIds = get(state, 'customFilter1FilterIds', [])
    const customFilter2FilterIds = get(state, 'customFilter2FilterIds', [])
    const customFilter3FilterIds = get(state, 'customFilter3FilterIds', [])
    const customFilterOptions = get(state, 'customFilterOptions', {})
    const distanceFilter = get(state, 'distanceFilter', 100)
    const sortBy = get(state, 'sortBy', '')
    const descOrAsc = get(state, 'descOrAsc', 'asc')

    const searchMethod =
      bucketType === 'near' ? this.handleSearchNearBy : this.handlePageSearch

    this.setState(
      {
        cacheFilters,
        customFilter1FilterIds,
        customFilter2FilterIds,
        customFilter3FilterIds,
        customFilterOptions,
        distanceFilter,
        sortBy,
        descOrAsc,
      },
      () => searchMethod(),
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const oldParams = get(this.props, 'match.params')
    const newParams = get(nextProps, 'match.params')

    const categoryId = get(oldParams, 'categoryId')
    const nextCategoryId = get(newParams, 'categoryId')
    const nextCategoryFullName = get(newParams, 'categoryFullName')
    const isCategoryChanged = categoryId !== nextCategoryId

    if (isCategoryChanged) {
      this.handleSetNextCategoryValue({
        categoryId: nextCategoryId,
        categoryFullName: nextCategoryFullName,
      })
    }

    const isSearchChanged =
      (newParams.bucketId && oldParams.bucketId !== newParams.bucketId) ||
      (newParams.bucketType && oldParams.bucketType !== newParams.bucketType) ||
      (newParams.searchType && oldParams.searchType !== newParams.searchType)

    const searchMethod =
      newParams.bucketType === 'near'
        ? this.handleSearchNearBy
        : this.handlePageSearch

    if (isCategoryChanged || isSearchChanged) {
      this.setState(
        {
          bucketName: newParams.bucketName,
          bucketId: newParams.bucketId,
          bucketType: newParams.bucketType,
          bucketFullName: newParams.bucketFullName,
          currentPage: 1,
          cacheFilters: {
            [newParams.bucketId]: null,
            [oldParams.bucketId]: null,
          },
        },
        () => searchMethod(),
      )
    }
  }

  get searchSelectCategoryValue() {
    const { categoryValue } = this.props

    const { categoryId, categoryFullName } =
      Utils.getCategoryProps(categoryValue)

    if (categoryId && categoryFullName) {
      return {
        label: categoryFullName,
        value: categoryId,
      }
    }

    return null
  }

  get searchOptions() {
    const { options } = this.state
    const { featuredLocations } = this.props
    const featureLocationOptions = processFeaturedLocations({
      locations: featuredLocations,
    })

    return [...options, featureLocationOptions]
  }

  handleSetNextCategoryValue = ({ categoryId, categoryFullName }) => {
    const { categories, onSetCategoryValue } = this.props

    const categoryValue = this.handleBuildCategoryValueFromUrl({
      categoryId,
      categoryFullName,
      categories,
    })

    onSetCategoryValue(categoryValue)
  }

  handleSetSearchValue = value => () => {
    const { onSetSearchValue } = this.props

    onSetSearchValue({
      label: value.bucket_name,
      type: value.bucket_type,
      value,
    })
  }

  getBreadCrumbs = () => {
    const { searchResults } = this.state
    const { match } = this.props
    const categoryId = get(match, 'params.categoryId')
    const categoryFullName = get(match, 'params.categoryFullName')
    const buckets = get(
      searchResults,
      Utils.getSearchResultsGeoBucketPath(searchResults),
    )

    return map(buckets, (bucket, bucketType) => {
      const queryParams = {
        ...processBucketForSearchPathParams(
          { ...bucket, bucket_type: bucketType },
          1,
        ),
        categoryId,
        categoryFullName,
      }
      return {
        id: queryParams.bucketId,
        element:
          bucketType !== 'state' ? (
            <NavLink
              exact
              key={queryParams.bucketId}
              to={PUBLIC_PATHS.SEARCH_QUERY(queryParams)}
              onClick={this.handleSetSearchValue({
                ...bucket,
                bucket_type: bucketType,
              })}
            >
              {queryParams.bucketName}
            </NavLink>
          ) : (
            queryParams.bucketName
          ),
      }
    })
  }

  getFilterItems = (type, items) =>
    map(keys(get(items, type)), id => ({
      id,
      name: get(items, `${type}.${id}.name`),
      count: get(items, `${type}.${id}.count`),
    }))

  getMarketplaceServiceType = () => {
    const { bucketType } = this.state
    const { baseTheme } = this.props

    if (bucketType === 'online') {
      return MARKETPLACE_SERVICE_TYPE.online
    }

    return get(baseTheme, 'marketplace_service_type')
  }

  handlePageSearch = () => {
    const {
      sortBy,
      descOrAsc,
      bucketId,
      currentPage,
      bucketType,
      cacheFilters,
      customFilter1FilterIds,
      customFilter2FilterIds,
      customFilter3FilterIds,
      customFilterOptions,
      distanceFilter,
      searchView,
    } = this.state

    const {
      baseTheme,
      categoryValue,
      history,
      match,
      searchDate,
      servicesAvailability,
      servicesNextAvailableDate,
      onSearchMerchants,
      onSearchServices,
    } = this.props

    const searchType = get(match, 'params.searchType')
    const onSearch =
      searchType === SEARCH_TYPE.BUSINESSES
        ? onSearchMerchants
        : onSearchServices

    const marketplaceServiceType = this.getMarketplaceServiceType()
    const marketplaceType = get(baseTheme, 'marketplace_type')

    if (
      (bucketType && bucketId) ||
      bucketType === 'online' ||
      bucketType === 'category' ||
      bucketType === 'results'
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' })

      const bucketParams =
        marketplaceServiceType === MARKETPLACE_SERVICE_TYPE.online ||
        bucketType === 'category' ||
        bucketType === 'results'
          ? {}
          : {
              bucketId,
              bucketType,
            }
      const { categoryId, categoryType } = Utils.getCategoryProps(categoryValue)

      onSearch({
        ...bucketParams,
        sortBy,
        descOrAsc,
        page: currentPage,
        categoryBucketType: categoryType,
        categoryBucketId: categoryId,
        customFilter1FilterIds,
        customFilter2FilterIds,
        customFilter3FilterIds,
        customFilterOptions,
        distanceFilter: bucketType === 'suburb' ? distanceFilter : null,
        marketplaceServiceType,
        date: searchDate?.format('YYYY-MM-DD'),
      }).then(({ data }) => {
        const cacheFiltersBucketId = get(cacheFilters, bucketId)

        const bucketFilters = !isEmpty(cacheFiltersBucketId)
          ? cacheFiltersBucketId
          : data.filters

        const bucketName = get(
          data,
          `${Utils.getSearchResultsGeoBucketPath(
            data,
          )}.${bucketType}.bucket_name`,
        )

        // Update our search label
        const search = {
          label: bucketName,
          value: bucketId,
        }

        if (bucketType === 'online') {
          search.label = get(
            baseTheme,
            `labels.${Labels.PLACEHOLDER_ONLINE_SERVICES}`,
          )
        }

        if (bucketType === 'near') {
          search.label = _(MARKETPLACE_DROPDOWN_TEXT[marketplaceType])
        }

        if (bucketType === 'results') {
          search.label = get(
            baseTheme,
            `labels.${Labels.PLACEHOLDER_SEARCH_LOCATION}`,
          )
        }

        const showMap =
          marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online

        const resetSearchView = searchView === SEARCH_VIEW.MAP && !showMap

        this.setState({
          cacheFilters: {
            [bucketId]: bucketFilters,
          },
          bucketName,
          searchResults: data,
          search,
          searchView: resetSearchView ? SEARCH_VIEW.LIST : searchView,
        })

        if (searchDate) {
          this.handleLoadServiceAvailability(searchDate, servicesAvailability)
        } else {
          this.handleLoadServiceNextAvailableDate(servicesNextAvailableDate)
        }

        history.replace(history.location.pathname, {
          ...get(history, 'location.state', {}),
          cacheFilters: {
            [bucketId]: bucketFilters,
          },
        })
      })
    }
  }

  handleLoadNearMerchants = ({ latitude, longitude }) => {
    const { history, categoryValue, onLoadNearMerchants } = this.props
    return onLoadNearMerchants({ lat: latitude, lng: longitude }).then(
      ({ data, status }) => {
        if (!data || status !== 200) return

        const queryParams = processBucketForSearchPathParams(data, 1)
        const { categoryId, categoryFullName } =
          Utils.getCategoryProps(categoryValue)
        if (categoryId && categoryFullName) {
          queryParams.categoryId = categoryId
          queryParams.categoryFullName = categoryFullName
        }

        const search = {
          label: data.bucket_name,
          value: data,
        }

        this.setState(
          {
            ...queryParams,
            isLoadingGeo: false,
            geoError: null,
            search,
          },
          () => {
            history.replace(PUBLIC_PATHS.SEARCH_QUERY(queryParams), {
              ...get(history, 'location.state', {}),
            })
          },
        )
      },
    )
  }

  handleLoadCategories = categoryValue => {
    const { onLoadCategories } = this.props
    const { categoryId, categoryFullName } = this.state

    return onLoadCategories().then(result => {
      const categories = get(result, 'data')

      if (!categoryValue) {
        // No category has been set so pull it from the URL
        return this.handleBuildCategoryValueFromUrl({
          categoryId,
          categoryFullName,
          categories,
        })
      }

      return processBuildCategoryValue(categoryValue, categories)
    })
  }

  handleBuildCategoryValueFromUrl = ({
    categoryId,
    categoryFullName,
    categories,
  }) => {
    const categoryValueFromURL = processFindCategoryValueFromUrl({
      categoryId,
      categoryFullName,
      categories,
    })

    return processBuildCategoryValue(
      { value: categoryValueFromURL },
      categories,
    )
  }

  handleSearchDateChange = async date => {
    const { searchDate, onSetSearchDate } = this.props

    if (date && date.isSame(searchDate)) {
      return
    }

    await onSetSearchDate(date)

    this.handlePageSearch()
  }

  getServiceIdsFromSearchResults = (filterByScheduled = false) => {
    const { searchResults } = this.state

    let services = searchResults?.data || []

    if (searchResults.meta) {
      // Service search results
      services = map(services, result => result.service)
    } else {
      // Merchant search results
      services = flattenDeep(map(services, merchant => merchant.services))
    }

    if (filterByScheduled) {
      services = services.filter(service => service.is_scheduled)
    }

    return map(services, result => result.id)
  }

  handleLoadServiceAvailability = (searchDate, servicesAvailability) => {
    const { onLoadServicesAvailability } = this.props

    let services = this.getServiceIdsFromSearchResults()

    // If the date has not changed, filter out service IDs
    // from the array that we already have availability for
    services = services.filter(
      serviceId => !get(servicesAvailability, serviceId),
    )

    if (!services.length) {
      return
    }

    onLoadServicesAvailability({
      date: searchDate.format('YYYY-MM-DD'),
      services,
    })
  }

  handleLoadServiceNextAvailableDate = servicesNextAvailableDate => {
    const { onLoadServicesNextAvailableDate } = this.props

    let services = this.getServiceIdsFromSearchResults(true)

    // Filter out services that we already have the next available
    // date for from previous requests
    services = services.filter(
      serviceId => !has(servicesNextAvailableDate, serviceId),
    )

    if (!services.length) {
      return
    }

    onLoadServicesNextAvailableDate(services)
  }

  handleSearchAll = () => {
    this.handleChangeCategoryFilter(null)
  }

  handleSearchNearBy = () => {
    this.setState({ isLoadingGeo: true })

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords
          this.handleLoadNearMerchants({ latitude, longitude })
        },
        error => {
          // eslint-disable-next-line no-console
          console.error(error)

          this.setState({ geoError: handleGeoError(error) })
          this.handleLoadNearMerchants({ latitude: null, longitude: null })
        },
        geoOptions,
      )
    }
  }

  handleSearch = search => {
    const { baseTheme, onLoadSuggestions } = this.props
    const marketplaceType = get(baseTheme, 'marketplace_type')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    onLoadSuggestions({
      term: search,
      serviceType: marketplaceServiceType,
    }).then(restaurants => {
      const { data } = restaurants

      const region = processLocations(
        get(data, 'location.region', []),
        'region',
      )

      const suburb = processLocations(
        get(data, 'location.suburb', []),
        'suburb',
      )

      const locations = {
        ...region,
        options: [...region.options, ...suburb.options],
      }

      const merchant = processMerchants(get(data, 'merchant', []), 'merchant')

      const options = [
        ...initOptions({ baseTheme, marketplaceType, marketplaceServiceType }),
        locations,
        merchant,
      ]

      this.setState({
        options,
        disabledSearch: false,
      })
    })
  }

  handlePageChange = currentPage => {
    const { history, categoryValue } = this.props

    this.setState({ currentPage }, () => {
      const { bucketFullName, bucketId, bucketType, sortBy, descOrAsc } =
        this.state

      const queryParams = {
        bucketType,
        bucketId,
        bucketFullName,
        currentPage,
      }

      const { categoryId, categoryFullName } =
        Utils.getCategoryProps(categoryValue)

      if (categoryId && categoryFullName) {
        queryParams.categoryId = categoryId
        queryParams.categoryFullName = categoryFullName
      }

      history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams), {
        sortBy,
        descOrAsc,
      })

      this.handlePageSearch()
    })
  }

  handleInputChange = (search, { action }) => {
    if (search || action === 'input-change') {
      this.setState({ search })
    }
    if (search.length < 2) return
    this.setState({ disabledSearch: true })
    this.debounceSearch(search)
  }

  handleSelectionOption = option => {
    const { baseTheme, categoryValue, history, onSetSearchValue } = this.props
    const marketplaceType = get(baseTheme, 'marketplace_type')

    this.setState({ search: option })

    onSetSearchValue(option)

    this.handleResetCustomFilters()

    if (!option || isEmpty(get(option, 'label'))) {
      this.setState(
        {
          search: {
            label: get(
              baseTheme,
              `labels.${Labels.PLACEHOLDER_SEARCH_LOCATION}`,
            ),
          },
          bucketFullName: null,
          bucketId: null,
          bucketName: null,
          bucketType: 'category',
        },
        () => {
          if (categoryValue == null) {
            const historyState = {
              ...get(history, 'location.state', {}),
            }

            const pathName = processBucketForAllResultsSearchPathParams()
            history.push(PUBLIC_PATHS.SEARCH_QUERY(pathName), historyState)
          } else {
            this.handleSelectionCategoryOption(categoryValue)
          }
        },
      )

      return
    }

    if (option.value === 'near') {
      this.handleSearchNearBy()
      return
    }

    if (option.type !== 'merchant') {
      const queryParams = processBucketForSearchPathParams(
        { ...option.value, bucket_type: option.type },
        1,
      )
      const { categoryId, categoryFullName } =
        Utils.getCategoryProps(categoryValue)
      if (categoryId && categoryFullName) {
        queryParams.categoryId = categoryId
        queryParams.categoryFullName = categoryFullName
      }

      history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams), {
        ...get(history, 'location.state', {}),
      })

      this.setState({ ...queryParams })

      return
    }

    if (option.type === 'merchant') {
      history.push(
        PUBLIC_PATHS.MERCHANT({
          id: get(option, 'value.merchant_token'),
          slug: get(option, 'value.merchant_slug'),
          marketplaceType,
        }),
        {
          ...history.location.state,
          prevPath: history.location.pathname,
        },
      )
    }
  }

  handleInputCategoryChange = (categorySearch, { action }) => {
    this.setState({ categorySearch })

    if (categorySearch || action === 'input-change') {
      this.setState({ categorySearch })
    }

    if (categorySearch.length < 2) return

    this.setState({ disabledCategorySearch: true })
    this.debounceCategorySearch(categorySearch)
  }

  handleSelectionCategoryOption = async selectedCategory => {
    const { baseTheme, onSetCategoryValue, history, categories, searchValue } =
      this.props

    const {
      bucketType,
      bucketId,
      bucketName,
      bucketFullName,
      currentPage,
      search,
    } = this.state

    const marketplaceType = get(baseTheme, 'service_type')
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')
    const categoryValue = processBuildCategoryValue(
      selectedCategory,
      categories,
    )

    const historyState = {
      ...get(history, 'location.state', {}),
    }

    if (get(selectedCategory, 'type') === 'merchant') {
      history.push(
        PUBLIC_PATHS.MERCHANT({
          id: get(selectedCategory, 'value.merchant_token'),
          slug: get(selectedCategory, 'value.merchant_slug'),
          marketplaceType,
        }),
        {
          historyState,
          prevPath: history.location.pathname,
        },
      )
      return
    }

    if (categoryValue == null && searchValue == null && search?.value == null) {
      const pathName = processBucketForAllResultsSearchPathParams(currentPage)
      history.push(PUBLIC_PATHS.SEARCH_QUERY(pathName), historyState)
    } else if (
      marketplaceServiceType === MARKETPLACE_SERVICE_TYPE.online &&
      categoryValue != null
    ) {
      const pathName = processBucketForOnlineSearchPathParams({
        ...get(categoryValue, 'value'),
        currentPage,
      })

      history.push(PUBLIC_PATHS.SEARCH_QUERY(pathName), historyState)
    } else {
      const queryParams = {
        bucketType,
        bucketId,
        bucketName,
        bucketFullName,
        currentPage,
      }
      const { categoryId, categoryFullName } =
        Utils.getCategoryProps(categoryValue)
      if (categoryId && categoryFullName) {
        queryParams.categoryId = categoryId
        queryParams.categoryFullName = categoryFullName
      }

      const isCategoryBucketType =
        bucketType === 'category' || bucketType === 'results'

      if (isCategoryBucketType && categoryFullName) {
        history.push(
          PUBLIC_PATHS.SEARCH_QUERY({
            bucketType: 'category',
            bucketId: categoryId,
            bucketFullName: categoryFullName,
          }),
          historyState,
        )
      } else {
        if (isCategoryBucketType) {
          queryParams.bucketType = 'online'
        }

        history.push(PUBLIC_PATHS.SEARCH_QUERY(queryParams), historyState)
      }
    }

    this.handleResetCustomFilters()

    await onSetCategoryValue(categoryValue)
  }

  handleFocus = () => {
    try {
      this.inputRef.current.focus()
    } catch (error) {
      //
    }
  }

  handleCategorySearch = categorySearch => {
    const { baseTheme, onLoadCategorySuggestions } = this.props
    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    this.setState({ disabledCategorySearch: true })

    onLoadCategorySuggestions({
      term: categorySearch,
      serviceType: marketplaceServiceType,
    }).then(({ data }) => {
      const categories = processCategories(data.categories, 'categories')

      const merchant = processMerchants(get(data, 'merchants', []), 'merchant')

      const categoryOptions = [categories, merchant]

      this.setState({ categoryOptions, disabledCategorySearch: false })
    })
  }

  handleFocusCategoryInput = async () => {
    const { categoryValue, featuredCategories, onLoadFeaturedCategories } =
      this.props

    let categories = []

    if (!isEmpty(featuredCategories)) {
      categories = featuredCategories
    } else {
      const { data } = await onLoadFeaturedCategories()
      categories = data
    }

    const categoryOptions = processFeaturedCategories({
      categories,
      type: 'categories',
      categoryValue,
    })

    this.setState({ categoryOptions: [categoryOptions] })
  }

  handleChangeCategoryFilter = async categoryValue => {
    if (Array.isArray(categoryValue) && isEmpty(categoryValue)) return

    this.handleResetCustomFilters()

    await this.handleSelectionCategoryOption(categoryValue)
  }

  handleResetCustomFilters = () => {
    const { history } = this.props
    history.replace(history.location.pathname, {
      ...get(history, 'location.state', {}),
      customFilter1FilterIds: [],
      customFilter2FilterIds: [],
      customFilter3FilterIds: [],
      customFilterOptions: {},
    })

    this.setState({
      customFilter1FilterIds: [],
      customFilter2FilterIds: [],
      customFilter3FilterIds: [],
      customFilterOptions: {},
    })
  }

  handleChangeServiceCustomFilter = (value, customFilterId) => {
    const { customFilterOptions } = this.state

    customFilterOptions[customFilterId] = value || []

    this.setState(
      { customFilterOptions, currentPage: 1 },
      this.handlePageSearch,
    )

    const { match, history } = this.props
    const query = get(match, 'params')

    // Reset page back to 1
    query.currentPage = 1

    history.replace(PUBLIC_PATHS.SEARCH_QUERY(query), {
      ...get(history, 'location.state', {}),
      customFilterOptions,
    })
  }

  handleChangeFilter = (type, customFilterId) => value => {
    if (type === 'customFilterOptions') {
      this.handleChangeServiceCustomFilter(value, customFilterId)

      return
    }

    const { [type]: stateFilterValue } = this.state

    if (isEqual(stateFilterValue, value)) {
      return
    }

    this.setState({ [type]: value, currentPage: 1 }, this.handlePageSearch)

    const { match, history } = this.props
    const query = get(match, 'params')

    // Reset page back to 1
    query.currentPage = 1

    history.replace(PUBLIC_PATHS.SEARCH_QUERY(query), {
      ...get(history, 'location.state', {}),
      [type]: value,
    })
  }

  handleChangeRangeFilter = value => {
    const { distanceFilter: stateValue } = this.state

    if (isEqual(stateValue, value)) {
      return
    }

    this.setState(
      { distanceFilter: value, currentPage: 1 },
      this.handlePageSearch,
    )

    const { match, history } = this.props
    const query = get(match, 'params')

    // Reset page back to 1
    query.currentPage = 1

    history.replace(PUBLIC_PATHS.SEARCH_QUERY(query), {
      ...get(history, 'location.state', {}),
      distanceFilter: value,
    })
  }

  handleChangeSearchType = searchType => {
    const { match, history } = this.props

    const query = get(match, 'params')

    query.searchType = searchType
    query.currentPage = 1

    history.replace(PUBLIC_PATHS.SEARCH_QUERY(query), {
      ...get(history, 'location.state', {}),
      sortBy: '',
      descOrAsc: 'asc',
    })

    this.setState(
      {
        currentPage: 1,
        sortBy: '',
        descOrAsc: 'asc',
      },
      this.handlePageSearch,
    )
  }

  handleSearchViewChange = searchView => {
    this.setState({ searchView, mobileFilter: false }, () => {
      // Ensure any lazy loaded components in view are rendered
      forceCheck()
    })
  }

  handleSearchSortChange = option => {
    const { sort, order } = option

    this.setState(
      { sortBy: sort, descOrAsc: order, currentPage: 1 },
      this.handlePageSearch,
    )

    const { history } = this.props
    history.replace(history.location.pathname, {
      ...get(history, 'location.state', {}),
      sortBy: sort,
      descOrAsc: order,
    })
  }

  handleGoBack = () => {
    const { history } = this.props
    history.goBack()
  }

  handleToggleMobileFilter = e => {
    e.preventDefault()
    e.stopPropagation()

    const { mobileFilter } = this.state

    const {
      breakpoint,
      theme: { breakpointNames },
    } = this.props

    const isPortableDevice =
      breakpoint === breakpointNames.MOBILE ||
      breakpoint === breakpointNames.TABLET ||
      breakpoint === breakpointNames.DESKTOP

    if (isPortableDevice) {
      if (!mobileFilter) {
        // When the panel, we want a fixed body
        document.body.style.position = 'fixed'
        document.body.style.top = `-${window.scrollY}px`
      } else {
        // When the panel is hidden...
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1)
      }
    }

    this.setState(state => ({ mobileFilter: !state.mobileFilter }))
  }

  getCustomFilters = () => {
    const { searchResults } = this.state

    // Service search results
    if (searchResults?.meta) {
      return map(searchResults.meta.client_custom_filters, customFilter => {
        const options = map(customFilter.options, option => {
          return {
            ...option,
            label: option.value,
            value: option.id,
            count: option.total,
          }
        })

        return { ...customFilter, options }
      })
    }

    // Business search results
    const { bucketId, cacheFilters } = this.state
    const { baseTheme } = this.props
    const customFilters = get(baseTheme, 'custom_filters')

    return transform(
      customFilters,
      (acc, filterValue, filterName) => {
        const options = transform(
          filterValue.options,
          (sum, value, key) => {
            // We only want to show filters relevant to the search results
            if (!get(cacheFilters, `${bucketId}.${filterName}.${key}`)) {
              return
            }

            const count = get(
              cacheFilters,
              `${bucketId}.${filterName}.${key}.count`,
              0,
            )

            const sortIndex = get(
              cacheFilters,
              `${bucketId}.${filterName}.${key}.sortIndex`,
              0,
            )

            sum.push({
              value: String(key),
              label: value,
              count,
              sortIndex,
            })
          },
          [],
        )

        acc.push({
          name: filterName,
          index: filterValue.index,
          label: filterValue.label,
          options: orderBy(options, 'sortIndex'),
        })
      },
      [],
    )
  }

  getActiveFilterCount = () => {
    const { categoryValue, match, searchDate } = this.props
    const searchType = get(match, 'params.searchType')

    const {
      search,
      customFilter1FilterIds,
      customFilter2FilterIds,
      customFilter3FilterIds,
      customFilterOptions,
    } = this.state

    let count = 0

    // Geobucket Dropdown
    if (search?.value) {
      count += 1
    }

    // Category Dropdown
    if (categoryValue?.value) {
      count += 1
    }

    // Datepicker
    if (searchDate) {
      count += 1
    }

    // Custom Filters
    if (searchType === SEARCH_TYPE.BUSINESSES) {
      const customFiltersActive = [
        ...customFilter1FilterIds,
        ...customFilter2FilterIds,
        ...customFilter3FilterIds,
      ]

      if (customFiltersActive.length) {
        count += customFiltersActive.length
      }
    } else {
      count += flatMapDeep(customFilterOptions).length
    }

    return count
  }

  getSearchLabel = () => {
    const { categoryValue } = this.props
    const { search } = this.state

    const searchLabel = search?.label || ''
    const categoryLabel = get(
      categoryValue,
      'value.category_2.bucket_name',
      get(categoryValue, 'value.bucket_name', ''),
    )

    if (searchLabel && categoryLabel) {
      return `${searchLabel} - ${categoryLabel}`
    }

    if (!searchLabel) {
      return categoryLabel
    }

    return searchLabel
  }

  render() {
    const {
      bucketId,
      bucketName,
      bucketType,
      currentPage,
      disabledSearch,
      search,
      searchResults,
      mobileFilter,
      isLoadingGeo,
      // eslint-disable-next-line no-unused-vars
      geoError,
      disabledCategorySearch,
      categorySearch,
      categoryOptions,
      searchView,
    } = this.state

    const {
      baseTheme,
      breakpoint,
      isSearchLoading,
      searchError,
      theme: { breakpointNames },
      isFeaturedCategoriesLoading,
      isFeaturedLocationsLoading,
      categoryValue,
      searchValue,
      isLoadingCategories,
      categories,
      featuredLocations,
      searchDate,
    } = this.props

    if (searchError) {
      return <ErrorContent error={searchError} onBack={this.handleGoBack} />
    }

    const paginationPath = Utils.getSearchResultsPaginationPath(searchResults)
    const total = get(searchResults, `${paginationPath}.total`, 0)
    const perPage = get(searchResults, `${paginationPath}.per_page`, 0)
    const showPagination = total > 0 && total > perPage

    const items = get(searchResults, 'data') || []
    const isLoading = isSearchLoading || isLoadingGeo || isLoadingCategories
    const isPortableDevice =
      breakpoint === breakpointNames.MOBILE ||
      breakpoint === breakpointNames.TABLET ||
      breakpoint === breakpointNames.DESKTOP

    const isMobileMapViewVisible =
      isPortableDevice && searchView === SEARCH_VIEW.MAP

    const marketplaceServiceType = get(baseTheme, 'marketplace_service_type')

    const isSearchByServiceEnabled = get(baseTheme, 'search_by_service', false)

    const canShowNotFound =
      searchResults &&
      !isLoading &&
      total === 0 &&
      (bucketId ||
        bucketType === 'online' ||
        bucketType === 'category' ||
        bucketType === 'results')

    const serviceCategory = get(baseTheme, 'service_category') ? 1 : 0
    const customFilters = this.getCustomFilters()

    const searchPlaceholder = get(
      baseTheme,
      `labels.${Labels.PLACEHOLDER_SEARCH_LOCATION}`,
    )

    const categorySearchPlaceholder = get(
      baseTheme,
      `labels.${Labels.PLACEHOLDER_SEARCH_CATEGORY}`,
    )

    const categoryLabel = categoryValue
      ? get(
          categoryValue,
          'value.category_2.bucket_name',
          get(categoryValue, 'value.bucket_name'),
        )
      : ''

    const roundedBorder = get(baseTheme, 'colors.rounded_border') ? 1 : 0

    const featuredLocationOptions =
      this.getLocationFilterOptions(featuredLocations)

    const featuredLocationValue = this.getFeaturedLocationValue({
      searchValue,
      featuredLocationOptions,
      search,
    })

    const showMap = marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online

    const showDatePicker = get(baseTheme, 'show_availability', true)

    const activeFilterCount = this.getActiveFilterCount()

    const searchLabel = this.getSearchLabel()

    const renderSearchFields = () => {
      return (
        <SearchFields>
          {!isPortableDevice && searchView === SEARCH_VIEW.MAP && (
            <SearchTypeSwitch
              mr={24}
              width="100%"
              onChange={this.handleChangeSearchType}
            />
          )}
          {serviceCategory ? (
            <SearchSelect
              filterOption={() => true}
              isDisabledSelect={
                disabledCategorySearch || isFeaturedCategoriesLoading
              }
              isLoading={disabledCategorySearch || isFeaturedCategoriesLoading}
              mb={marketplaceServiceType ? [3, 3, 3, 0] : 0}
              mr={marketplaceServiceType ? [0, 0, 0, 3] : 0}
              options={categoryOptions}
              placeholder={categorySearchPlaceholder}
              value={
                !categoryValue?.value
                  ? {
                      label: categorySearchPlaceholder,
                    }
                  : categorySearch || this.searchSelectCategoryValue
              }
              onBlur={() => {}}
              onChange={this.handleSelectionCategoryOption}
              onFocus={this.handleFocusCategoryInput}
              onInputChange={this.handleInputCategoryChange}
            />
          ) : null}

          {marketplaceServiceType ? (
            <SearchSelect
              customGlyph={MapPinIcon}
              fieldRef={this.inputRef}
              filterOption={() => true}
              isDisabledSelect={disabledSearch}
              isLoading={disabledSearch || isFeaturedLocationsLoading}
              mb={[3, 3, 3, 0]}
              mr={showDatePicker ? [0, 0, 0, 3] : 0}
              options={this.searchOptions}
              placeholder={searchPlaceholder}
              value={
                ((search?.label && search) || searchValue) ?? {
                  label: searchPlaceholder,
                }
              }
              onBlur={() => {}}
              onChange={this.handleSelectionOption}
              onFocus={this.handleFocusSearchInput}
              onInputChange={this.handleInputChange}
            />
          ) : null}

          {showDatePicker && (
            <SearchDatePickerHolder>
              <SearchDatePicker
                date={searchDate}
                noBorder
                rounded={roundedBorder}
                shadow={1}
                small={isPortableDevice}
                onDateChange={this.handleSearchDateChange}
              />
            </SearchDatePickerHolder>
          )}
        </SearchFields>
      )
    }

    const renderFilters = (pb, height) => {
      return (
        <ScrollContainer
          loading={isSearchLoading ? 1 : 0}
          maxheight={height}
          pb={pb}
        >
          <Filters
            categoryFilterOptions={this.getCategoryFilterOptions(categories)}
            categoryValue={categoryValue}
            customFilters={customFilters}
            featuredLocationOptions={featuredLocationOptions}
            featuredLocationValue={featuredLocationValue}
            getActiveFilters={this.getActiveFilters}
            isSearchLoading={isSearchLoading}
            showFeaturedLocation={!isEmpty(featuredLocations)}
            typeFilterOptions={this.getTypeFilterOptions(categories)}
            onChangeCategoryFilter={this.handleChangeCategoryFilter}
            onChangeFilter={this.handleChangeFilter}
            onChangeRangeFilter={this.handleChangeRangeFilter}
            onChangeSearchType={this.handleChangeSearchType}
            onSelectionOption={this.handleSelectionOption}
          />
        </ScrollContainer>
      )
    }

    const renderFiltersToggle = () => {
      return (
        <>
          <MobileFiltersToggleContainer onClick={this.handleToggleMobileFilter}>
            <AdjustIcon />
            Filters
            {activeFilterCount > 0 && (
              <MobileFiltersCountBadge>
                {activeFilterCount}
              </MobileFiltersCountBadge>
            )}
          </MobileFiltersToggleContainer>
          <MobileFiltersOverlay
            display={mobileFilter ? 'flex' : 'none'}
            onClick={this.handleToggleMobileFilter}
          />
          <MobileFiltersContainer open={mobileFilter}>
            {isPortableDevice && (
              <>
                <MobileFiltersContainerTitle>
                  {_('filterBy.by')}
                  <CloseMobileFiltersIcon
                    onClick={this.handleToggleMobileFilter}
                  />
                </MobileFiltersContainerTitle>
                {renderSearchFields()}

                <Dash mb={2} pt="1px" px={3} />
              </>
            )}

            {renderFilters(70)}

            <MobileFiltersResultsButtonContainer>
              <MobileFiltersShowResultsButton
                bg={get(baseTheme, 'colors.primary_background')}
                color={get(baseTheme, 'colors.primary_text')}
                roundedborder={roundedBorder}
                onClick={this.handleToggleMobileFilter}
              >
                Show Results
              </MobileFiltersShowResultsButton>
            </MobileFiltersResultsButtonContainer>
          </MobileFiltersContainer>
        </>
      )
    }

    const renderMobileSearchFields = () => {
      return (
        <MobileSearchFields>
          <MobileSearchFieldsContainer
            width={isMobileMapViewVisible ? '100%' : 'calc(100% + 32px)'}
          >
            <SearchTypeSwitch
              mt={['12px', '12px', '12px', 3]}
              width="100%"
              onChange={this.handleChangeSearchType}
            />
            <SearchResultsPanel
              withShowMap={showMap}
              onSearchSortChange={this.handleSearchSortChange}
              onSearchViewChange={this.handleSearchViewChange}
            >
              {renderFiltersToggle()}
            </SearchResultsPanel>
          </MobileSearchFieldsContainer>

          {!canShowNotFound && !isLoading && (
            <SearchLabel>{searchLabel}</SearchLabel>
          )}
        </MobileSearchFields>
      )
    }

    return (
      <Container>
        <Responsive
          pb={isMobileMapViewVisible ? 0 : 4}
          px={isMobileMapViewVisible ? 0 : CONTENT_PADDING_X}
        >
          <Row>
            {marketplaceServiceType !== MARKETPLACE_SERVICE_TYPE.online && (
              <StyledBreadcrumbs links={this.getBreadCrumbs()} />
            )}
          </Row>
          <Content>
            {searchView !== SEARCH_VIEW.MAP && (
              <DesktopLeftSide>
                <SearchTypeSwitch
                  mb="3"
                  onChange={this.handleChangeSearchType}
                />
                <FilterContainer>
                  {renderFilters(
                    0,
                    isSearchByServiceEnabled
                      ? 'calc(100dvh - 180px)'
                      : 'calc(100dvh - 110px)',
                  )}
                </FilterContainer>
              </DesktopLeftSide>
            )}

            <MainContent>
              {isPortableDevice ? (
                renderMobileSearchFields()
              ) : (
                <>
                  {renderSearchFields()}
                  <SearchResultsPanel
                    withShowMap={showMap}
                    onSearchSortChange={this.handleSearchSortChange}
                    onSearchViewChange={this.handleSearchViewChange}
                  >
                    {searchView === SEARCH_VIEW.MAP && renderFiltersToggle()}
                  </SearchResultsPanel>
                </>
              )}

              {isLoading && (
                <LoaderOverlay>
                  <Loader
                    color={get(baseTheme, 'colors.secondary_background')}
                  />
                </LoaderOverlay>
              )}

              <SearchResult
                display={!isLoading ? 'flex' : 'none'}
                paginated={showPagination ? 1 : 0}
                results={items}
                searchView={searchView}
                withShowMap={showMap}
              />

              {showPagination && !isLoading && (
                <Pagination
                  current={currentPage}
                  mt={isMobileMapViewVisible ? '6px' : '16px'}
                  perPage={perPage}
                  total={total}
                  onChange={this.handlePageChange}
                />
              )}

              {canShowNotFound && (
                <NotFound
                  bucket={bucketName}
                  category={categoryLabel}
                  hideImage={isPortableDevice}
                  hideNearMe={
                    this.getMarketplaceServiceType() ===
                    MARKETPLACE_SERVICE_TYPE.online
                  }
                  searchDate={searchDate}
                  onFallback={
                    this.getMarketplaceServiceType() ===
                    MARKETPLACE_SERVICE_TYPE.online
                      ? this.handleSearchAll
                      : this.handleSearchNearBy
                  }
                />
              )}
            </MainContent>
          </Content>
        </Responsive>
      </Container>
    )
  }
}

Search.defaultProps = {
  categoryValue: null,
  featuredCategories: [],
  featuredLocations: [],
  searchError: null,
  searchValue: null,
  searchDate: null,
  servicesAvailability: null,
  servicesNextAvailableDate: null,
}

Search.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  breakpoint: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  categoryValue: PropTypes.object,
  featuredCategories: PropTypes.array,
  featuredLocations: PropTypes.array,
  history: PropTypes.object.isRequired,
  isFeaturedCategoriesLoading: PropTypes.bool.isRequired,
  isFeaturedLocationsLoading: PropTypes.bool.isRequired,
  isLoadingCategories: PropTypes.bool.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  searchDate: momentPropTypes.momentObj,
  searchError: PropTypes.object,
  searchValue: PropTypes.object,
  servicesAvailability: PropTypes.object,
  servicesNextAvailableDate: PropTypes.object,
  theme: PropTypes.object.isRequired,
  onLoadCategories: PropTypes.func.isRequired,
  onLoadCategorySuggestions: PropTypes.func.isRequired,
  onLoadFeaturedCategories: PropTypes.func.isRequired,
  onLoadNearMerchants: PropTypes.func.isRequired,
  onLoadServicesAvailability: PropTypes.func.isRequired,
  onLoadServicesNextAvailableDate: PropTypes.func.isRequired,
  onLoadSuggestions: PropTypes.func.isRequired,
  onSearchMerchants: PropTypes.func.isRequired,
  onSearchServices: PropTypes.func.isRequired,
  onSetCategoryValue: PropTypes.func.isRequired,
  onSetSearchDate: PropTypes.func.isRequired,
  onSetSearchValue: PropTypes.func.isRequired,
}

export default withRouter(withAppContext(Search))
